import { SubmitHandler, FieldValues } from 'react-hook-form';
import { useCallback, useState, useEffect, useRef } from 'react';
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Edit,
  SimpleForm,
  TextInput,
  PasswordInput,
  DateInput,
  Create,
  TopToolbar,
  ListButton,
  WithRecord,
  useDataProvider,
  useRefresh,
  BooleanField,
  BooleanInput,
  SelectInput,
  NumberInput,
  Toolbar,
  ToolbarProps,
  SaveButton,
  useGetList,
  Button,
  required,
  useNotify,
  useRedirect,
} from 'react-admin';
import { useParams } from 'react-router-dom';
import { useQuery, useMutation } from 'react-query';
import { Typography } from '@mui/material';
import { StructureProps } from './Structure';
import { debounce } from 'lodash';

interface UserProps {
  id: number;
  guid: number;
  username: string;
  email: string;
  last_name: string;
  first_name: string;
  display_name: string;
  created_utc: string;
  modified_utc: string;
  last_login_utc: string;
  is_admin: boolean;
  is_demo_account: boolean;
  demo_period_end_utc: string;
  origin_space_key: string;
  creator_user_id: string;
  is_classroom_account: boolean;
  classroom_structure_id: string;
  classroom_password: string;
  user_status: string;
  password?: string;
}

const user_status_options = [
  { id: 'ACTIVE', name: 'ACTIVE' },
  { id: 'PENDING_ACTIVATION', name: 'PENDING_ACTIVATION' },
];

const postFilters = [<TextInput label="Search" source="terms" alwaysOn />];

export const UserList = (props: UserProps) => {
  return (
    <List {...props} filters={postFilters}>
      <Datagrid>
        <TextField source="username" />
        <TextField source="first_name" />
        <TextField source="last_name" />
        <TextField source="display_name" />
        <TextField source="email" />
        <BooleanField source="is_admin" />
        <BooleanField source="is_demo_account" />
        <BooleanField source="is_classroom_accounts" />
        <TextField source="user_status" />
        <EditButton />
      </Datagrid>
    </List>
  );
};

const EditActions = () => (
  <TopToolbar>
    <ListButton label="Retour à la liste" />
  </TopToolbar>
);

export const UserCreate = () => {
  const notify = useNotify();
  const redirect = useRedirect();
  const dataProvider = useDataProvider();
  const [password, setPassword] = useState<string>('');

  const transform = (data: UserProps) => {
    const { password: _password, ...dataWithoutPassword } = data;
    if (_password) {
      setPassword(_password);
    }
    return dataWithoutPassword;
  };

  const passwordMutation = useMutation(
    [`admin/users/password`, 'updatePassword'],
    (id: number) => {
      if (password) {
        return dataProvider.updateListFrom(`admin/users/${id}/password`, {
          id: id || '',
          previousData: { password: null },
          data: { password },
        });
      } else {
        return Promise.resolve();
      }
    },
  );

  const onSuccess = async (data: UserProps) => {
    console.log('onSuccess data', data);
    console.log('onSuccess password', password);
    await passwordMutation.mutateAsync(data.id);
    notify(`User registered`);
    redirect(`/admin/users/${data.id}`);
  };

  return (
    <Create
      actions={<EditActions />}
      transform={transform}
      mutationOptions={{ onSuccess }}
    >
      <SimpleForm>
        <TextInput
          fullWidth
          source="username"
          required
          helperText="Minimum 4 caractères, parmi a-z A-Z 0-9 . - _"
        />
        <TextInput fullWidth source="first_name" />
        <TextInput fullWidth source="last_name" />
        <TextInput fullWidth source="display_name" />
        <TextInput fullWidth source="email" />
        <PasswordInput fullWidth source="password" />
        <BooleanInput fullWidth source="is_admin" />
        <SelectInput
          source="user_status"
          choices={user_status_options}
          validate={required()}
          emptyValue={0}
        />
      </SimpleForm>
    </Create>
  );
};

export const UserEdit = () => {
  const { id } = useParams();

  const dataProvider = useDataProvider();

  const { data: associatedAccounts } = useQuery(
    [`admin/users/${id}/accounts`, 'getListFrom', { enabled: true }],
    () => {
      return dataProvider.getListFrom(`admin/users/${id}/accounts`, {
        pagination: { page: 0, perPage: 999999 },
        sort: { field: 'id', order: 'ASC' },
        filter: {},
      });
    },
  );

  return (
    <>
      {associatedAccounts && (
        <>
          <Edit actions={<EditActions />}>
            <SimpleForm toolbar={<OnlySaveToolbar />}>
              <NumberInput fullWidth source="id" />
              <TextInput
                fullWidth
                source="username"
                required
                helperText="Minimum 4 caractères, parmi a-z A-Z 0-9 . - _"
              />
              <TextInput fullWidth source="first_name" />
              <TextInput fullWidth source="last_name" />
              <TextInput fullWidth source="display_name" />
              <TextInput fullWidth source="email" />
              <DateInput fullWidth source="created_utc" disabled />
              <DateInput fullWidth source="modified_utc" disabled />
              <BooleanInput fullWidth source="is_admin" />
              <SelectInput
                source="user_status"
                choices={user_status_options}
                validate={required()}
              />
            </SimpleForm>
          </Edit>
          <hr />
          <hr />
          <UserAccounts />
          <hr />
          <hr />
          <UserAddStructure />
          <br />
          <br />
          <br />
        </>
      )}
    </>
  );
};

const OnlySaveToolbar = (props: ToolbarProps) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

const UserAddStructure = () => {
  const { id } = useParams();
  const [searchString, setSearchString] = useState<string>('');
  const [selectedStructure, setSelectedStructure] = useState<
    StructureProps | undefined
  >();
  const [createAccountPayload, setCreateAccountPayload] = useState<
    Partial<StructureProps> | undefined
  >(undefined);
  const dataProvider = useDataProvider();
  const refresh = useRefresh();

  const getStructuresList = useGetList<StructureProps>(
    'admin/structures',
    {
      filter: { terms: searchString },
    },
    { enabled: false },
  );

  const { isSuccess: isCreateAccountSuccess, mutate: createAccount } =
    useMutation([`admin/accounts`, 'create', { enabled: true }], () => {
      return dataProvider
        .create(`admin/accounts`, { data: createAccountPayload || {} })
        .then(() => {
          refresh();
        })
        .catch(() => {
          refresh();
        });
    });

  const onSearch = useCallback((e: React.FormEvent<HTMLInputElement>) => {
    setSearchString(e.currentTarget.value);
  }, []);

  const onSubmit = useCallback(
    (e: any) => {
      const payload = {
        ...e,
        uai: '',
        structure_id: selectedStructure?.id,
      };
      delete payload.uai;
      setCreateAccountPayload(payload);
    },
    [selectedStructure],
  );

  const update = useRef(
    debounce(async () => {
      await getStructuresList.refetch();
    }, 1000),
  ).current;

  useEffect(() => {
    if (searchString && searchString.length > 4) {
      update();
    }
  }, [searchString, update]);

  useEffect(() => {
    if (createAccountPayload) {
      createAccount();
    }
  }, [createAccountPayload, createAccount]);

  useEffect(() => {
    if (isCreateAccountSuccess) {
      window.location.reload();
      // refresh();
    }
  }, [isCreateAccountSuccess]);

  return (
    <Create resource="admin/accounts">
      <SimpleForm onSubmit={onSubmit}>
        <Typography component="h2" variant="h6">
          Add a structure :
        </Typography>
        <NumberInput source="user_id" defaultValue={id} disabled />
        {!selectedStructure ? (
          <>
            <TextInput
              source="identifier"
              onChange={onSearch}
              value={searchString}
              label="Search by identifier or UAI"
            />
            {searchString &&
              (getStructuresList.isLoading ? (
                'Loading...'
              ) : (
                <>
                  {!!getStructuresList.total && getStructuresList.total > 25 ? (
                    <p>25 premiers résultats sur {getStructuresList.total} :</p>
                  ) : (
                    <p>{getStructuresList.total} résultats :</p>
                  )}
                  {getStructuresList.data &&
                  getStructuresList.data.length > 0 ? (
                    <>
                      {getStructuresList.data.map((structure) => (
                        <Button
                          key={structure.identifier}
                          label={`${structure.name} (${structure.identifier})`}
                          onClick={() => {
                            setSelectedStructure(structure);
                          }}
                        />
                      ))}
                    </>
                  ) : (
                    <div>Aucun résultat</div>
                  )}
                </>
              ))}
          </>
        ) : (
          <>
            <div style={{ display: 'none' }}>
              <TextInput source="uai" value={selectedStructure.identifier} />
            </div>
            <Button
              label="Reset"
              onClick={() => {
                setSearchString('');
                setSelectedStructure(undefined);
              }}
            />
            <h3>
              {selectedStructure.name} ({selectedStructure.identifier})
            </h3>
            <BooleanInput fullWidth source="is_structure_admin" />
            <BooleanInput fullWidth source="is_teacher" />
            <BooleanInput fullWidth source="is_learner" />
          </>
        )}
      </SimpleForm>
    </Create>
  );
};

export const UserAccounts = () => {
  const { id } = useParams();

  const dataProvider = useDataProvider();

  const [deleteAccountId, setDeleteAccountId] = useState<string>('');

  const { data: associatedAccounts } = useQuery(
    [`admin/users/${id}/accounts`, 'getListFrom', { enabled: true }],
    () => {
      return dataProvider.getListFrom(`admin/users/${id}/accounts`, {
        pagination: { page: 0, perPage: 999999 },
        sort: { field: 'id', order: 'ASC' },
        filter: {},
      });
    },
  );

  const refresh = useRefresh();
  const { mutate: updateAssociatedAccounts } = useMutation(
    [`admin/users/${id}/accounts`, 'updateListFrom'],
    (ids: Array<any>) =>
      dataProvider
        .updateListFrom(`admin/users/${id}/accounts`, {
          data: ids,
        })
        .then(() => {
          refresh();
        }),
  );

  const handleSubmit: SubmitHandler<FieldValues> = (data: FieldValues) => {
    updateAssociatedAccounts(associatedAccounts);
  };

  // const handleEdit = (id: number) => {
  //   console.log('Edit', id);
  // };

  const { isSuccess: isDeleteAccountSuccess, mutate: deleteAccount } =
    useMutation([`admin/accounts`, 'delete', { enabled: true }], () => {
      return dataProvider
        .delete(`admin/accounts`, { id: deleteAccountId })
        .then(() => {
          refresh();
        })
        .catch(() => {
          refresh();
        });
    });

  const handleDelete = (id: string) => {
    setDeleteAccountId(id);
  };

  useEffect(() => {
    if (deleteAccountId) {
      deleteAccount();
    }
  }, [deleteAccountId, deleteAccount]);

  useEffect(() => {
    if (isDeleteAccountSuccess) {
      // window.location.reload();
    }
  }, [isDeleteAccountSuccess]);

  return (
    <>
      {associatedAccounts && (
        <Edit>
          <SimpleForm toolbar={<></>} onSubmit={handleSubmit}>
            <Typography component="h2" variant="h6">
              User's Structures :
            </Typography>
            {associatedAccounts && (
              <Datagrid
                data={associatedAccounts}
                total={associatedAccounts.length}
                sort={{ field: 'id', order: 'ASC' }}
              >
                <TextField source="id" label="Account" />
                <TextField source="structure_name" />
                <TextField source="structure_identifier" />
                <BooleanField fullWidth source="is_structure_admin" />
                <BooleanField fullWidth source="is_teacher" />
                <BooleanField fullWidth source="is_learner" />
                <WithRecord
                  label="Delete"
                  render={(record) => (
                    <Button
                      label="delete"
                      onClick={() => {
                        handleDelete(`${record.id}`);
                      }}
                    />
                  )}
                />
              </Datagrid>
            )}
          </SimpleForm>
        </Edit>
      )}
    </>
  );
};
